<template>
    <div class="c_main">
        <div class="title" v-if="this.thisRobot && this.isChat!=='11111'">
            <div v-if="this.isChat==='10001'" class="ravs-top">
                <div class="top-left" @click="goToRobotMsg(this.thisRobot.id)">
                    <img :src="getFullImageUrl(this.thisRobot.avatar)" alt="" class="head">
                </div>
                <div class="top-msg">
                    <div class="msg-title">
                        {{ truncatedText(this.thisRobot.name, 5) }}

                    </div>
                    <div class="top-content">
                        {{ truncatedText(this.thisRobot.job, 6) }}
                    </div>
                </div>
                <div class="bottom">
                    <button @click="del_robot(this.$store.state.robot_ids[this.user_id],'gpt')">删除机器人</button>
                </div>
            </div>
            <div class="mmc-top" v-else-if="this.isChat==='10000'">
                <div class="mmc-title">主题</div>
                <div class="bottom">
                    <div class="bottom-theme">{{ truncatedText(this.thisRobot.theme, 8) }}</div>

                    <el-button @click="del_robot(this.$store.state.robot_ids[this.user_id],'mmc')">- 删除聊天室</el-button>
                </div>
            </div>
        </div>

        <div class="Ravs-chat">
            <div class="chat-robot" v-if="this.isChat!=='10000'">
                <div class="robot-func">
                    <div class="robot-source" @click="robot_source()">
                        <i class="fas fa-search"></i>
                        <div class="robot-msg">
                            <label>搜索</label>
                            <i class="fas fa-angle-right"></i>
                        </div>
                    </div>
                    <div class="robot-create" @click="robot_create()">
                        <i class="fas fa-robot"></i>
                        <div class="robot-msg">
                            <label>创建机器人</label>
                            <i class="fas fa-plus"></i>
                        </div>

                    </div>
                </div>
                <div v-for="i in this.robotRooms" class="chat_room"
                     @click="enterChat(i.chatRoom_id)"
                >
                    <img :src="getFullImageUrl(i.robot_id__avatar)" alt="">
                    <div class="msg">
                        {{ truncatedText(i.robot_id__name + '（' + i.robot_id__job + '）', 10) }}
                    </div>
                </div>
            </div>
            <div class="mmc-robot" v-else>
                <div class="change_theme" @click="goToMmc()">
                    <i class="fas fa-exchange-alt"></i>
                    <div class="robot-msg">
                        <label>更换主题</label>
                        <i class="fas fa-angle-right"></i>
                    </div>
                </div>
                <div v-for="i in this.robotRooms" class="mmc_room"
                     @click="enterRoom(i.chatRoom_id)">
                    <div class="msg">
                        主题：{{ truncatedText(i.theme, 8) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {ref, watch} from "vue";
import axios from "axios";
import config from "@/api/api";

export default {
    name: "Ravs",
    created() {
        watch(() => this.$store.state.titles[localStorage.getItem('token')], () => {
            this.getChatData();
        });
        watch(() => this.$store.state.robot_ids[localStorage.getItem('token')], () => {
            this.getChatData();
        });
    },

    data() {
        return {
            user_id: localStorage.getItem('token'),
            navList: [],
            showModal: false,
            robotRooms: '',
            robot_name: '',
            robot_head: '',
            isChat: '',
            thisRobot: '',
            modal: {
                name: '',
                api: 'gpt-3.5-turbo',
                key: '',
                flag: false,
            },
            gpt_api: [
                'gpt-3.5-turbo',
                'gpt-4',
            ]
        };
    },
    methods: {
        //获取信息
        getChatData() {
            this.isChat = this.$store.state.base_msgs[this.user_id].chat_id
            if (this.isChat === '10001') {
                this.getThisRobot()
                this.getRobotsRoom();
            } else if (this.isChat === '11111') {
                this.getRobotsRoom();
            } else if (this.isChat === '10000') {
                this.getThisMmcRobot()
                this.getMmcRobotsRoom()
            }
        },
        //获取机器人信息
        getThisRobot() {
            try {
                const robot_id = this.$store.state.robot_ids[this.user_id].replace(this.$store.state.users[this.user_id].id + '10001', '')
                if (robot_id < 10000 && robot_id !== '') {
                    axios.post(config.API_BASE_URL + 'front/getThisRobot', {robot_id: robot_id})
                        .then(response => {
                            // 请求成功的处理逻辑
                            if (response.data.thisRobot) {
                                this.thisRobot = response.data.thisRobot
                            } else {
                                this.thisRobot = ''
                            }
                        });
                } else {
                    if (robot_id === '') {
                        this.thisRobot = ''
                    }
                }

            } catch (error) {
                // 请求失败的处理逻辑
                this.robot_name = 'mmc智能';
                this.robot_head = 'avatar/robot/chatGPTAvatar.png';
            }
        },
        //获取所有机器人
        getRobotsRoom() {
            const data = {
                user_id: this.$store.state.users[this.user_id].id,
                chat_Id: '10001',
            };
            axios.post(config.API_BASE_URL + 'front/select_chatRoom', data)
                // 建立mysql数据库
                .then(response => {
                    // 请求成功的处理逻辑
                    if (response.data.state === 'error') {
                    } else {
                        // console.log(response.data.robotRooms)
                        this.robotRooms = response.data.robotRooms
                    }

                })
                .catch(error => {
                    // 请求失败的处理逻辑
                    console.error(error);
                    alert(error);
                });
        },
        //获取mmcgpt的数据()
        getThisMmcRobot() {
            try {
                // const robot_id = this.chatData.robot_id;
                const robot_id = this.$store.state.robot_ids[this.user_id]
                // if (robot_id !== '') {
                //     axios.post(config.API_BASE_URL + 'front/getMmcThisRobot', {robot_id: robot_id})
                //         .then(response => {
                //             // 请求成功的处理逻辑
                //             // console.log(response.data.thisRobot)
                //             if (response.data.thisRobot) {
                //                 this.thisRobot = response.data.thisRobot
                //                 console.log('当前机器人', response.data.thisRobot)
                //                 this.robot_name = response.data.thisRobot.theme
                //                 this.robot_head = ''
                //             }
                //         });
                // } else {
                //     if (robot_id === '') {
                //         this.robot_name = 'mmc智能聊天室';
                //         this.robot_head = 'avatar/robot/chatGPTAvatar.png';
                //     }
                //
                // }
                axios.post(config.API_BASE_URL + 'front/getMmcThisRobot', {robot_id: robot_id})
                    .then(response => {
                        // 请求成功的处理逻辑
                        // console.log(response.data.thisRobot)
                        this.thisRobot = response.data.thisRobot
                    });

            } catch (error) {
                // 请求失败的处理逻辑
                this.thisRobot = null
            }
        },
        //获取所有机器人
        getMmcRobotsRoom() {
            const data = {
                user_id: this.$store.state.users[this.user_id].id,
            };
            axios.post(config.API_BASE_URL + 'front/select_MmcChatRoom', data)
                // 建立mysql数据库
                .then(response => {
                    // 请求成功的处理逻辑
                    this.robotRooms = response.data.robotRooms

                })
                .catch(error => {
                    // 请求失败的处理逻辑
                    console.error(error);
                    alert(error);
                });
        },
        //进入聊天
        enterChat(robot_id) {
            const navList = this.$store.getters.getNavs;
            this.base_msg = navList.slice(0, 11);

            this.$store.state.base_msgs[this.user_id].page = this.base_msg[1].page;
            this.$store.state.titles[this.user_id] = this.base_msg[1].page;
            this.$store.state.base_msgs[this.user_id].title = this.base_msg[1].title;
            this.$store.state.base_msgs[this.user_id].url = this.base_msg[1].url;
            this.$store.state.robot_ids[this.user_id] = robot_id
            this.$store.state.base_msgs[this.user_id].robot_id = robot_id
            this.$store.state.base_msgs[this.user_id].mmc_chat = robot_id
            this.$store.state.base_msgs[this.user_id].chat_id = this.base_msg[1].chat_id;

            this.updateMongodb(this.$store.state.users[this.user_id].id, this.$store.state.base_msgs[this.user_id], 'all')//更新用户数据
            // this.$store.state.robot_ids[this.user_id] = robot_id
            // this.$store.state.base_msgs[this.user_id].robot_id = robot_id
            this.$router.push({name: this.base_msg[1].url})
            // this.updateMongodb(this.$store.state.users[this.user_id].id, this.$store.state.base_msgs[this.user_id], 'all')


        },
        //进入聊天室
        enterRoom(robot_id) {
            this.$store.state.robot_ids[this.user_id] = robot_id
            this.$store.state.base_msgs[this.user_id].robot_id = robot_id
            this.$store.state.base_msgs[this.user_id].mmc_interact = robot_id
            this.updateMongodb(this.$store.state.users[this.user_id].id, this.$store.state.base_msgs[this.user_id], 'all')
        },
        //进入对应机器人信息页面
        goToRobotMsg(robot_id) {
            // 加密参数
            const bot = robot_id + 254182 - 1928 + 454215
            const type = parseInt(this.$store.state.base_msgs[this.user_id].chat_id) + 7148982 - 192528 + 454215
            const navList = this.$store.getters.getNavs;
            this.base_msg = navList.slice(0, 9);
            // 数据变更
            this.$store.state.base_msgs[this.user_id].page = this.base_msg[8].page;
            this.$store.state.base_msgs[this.user_id].chat_id = this.base_msg[8].chat_id;
            this.$store.state.base_msgs[this.user_id].title = this.base_msg[8].title;
            this.$store.state.base_msgs[this.user_id].url = this.base_msg[8].url;
            this.$store.state.robot_ids[this.user_id] = ''
            //其他
            this.$store.state.titles[this.user_id] = this.base_msg[8].page;//更新标题名称
            this.updateMongodb(this.$store.state.users[this.user_id].id, this.$store.state.base_msgs[this.user_id], 'not_robotId')//更新用户数据
            //跳转
            this.$router.push({name: this.base_msg[8].url, query: {bot: bot, type: type}})


        },
        //进入mmc搜索页面
        goToMmc() {
            this.$store.state.robot_ids[this.user_id] = ''
            this.$store.state.base_msgs[this.user_id].robot_id = ''
            this.updateMongodb(this.$store.state.users[this.user_id].id, this.$store.state.base_msgs[this.user_id], 'all')//更新用户数据
            this.$router.push({name: 'mmcSetting'})
        },
        //删除gpt机器人
        del_robot(robot_id, type) {
            this.$confirm('确认删除当前机器人?', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.state.robot_ids[this.user_id] = ''
                this.$store.state.base_msgs[this.user_id].robot_id = ''
                if (type === 'gpt') {
                    this.$store.state.base_msgs[this.user_id].mmc_chat = ''
                } else {
                    this.$store.state.base_msgs[this.user_id].mmc_interact = ''
                }
                this.selectImageDBVersion(robot_id)
                this.updateMongodb(this.$store.state.users[this.user_id].id, this.$store.state.base_msgs[this.user_id], 'all')
                axios.post(config.API_BASE_URL + 'front/del_thisRobot', {'robot_id': robot_id, 'type': type})
                    // 建立mysql数据库
                    .then(response => {
                        // 请求成功的处理逻辑
                        if (response.data.state === 'success') {
                            if (type === 'gpt') {
                                this.thisRobot = '';
                                this.getRobotsRoom();
                                this.$router.push({name: 'index'})
                            } else if (type === 'mmc') {
                                this.$router.push({name: 'mmcSetting'})
                                this.getMmcRobotsRoom()
                            }

                        }
                    })
                    .catch(error => {
                        // 请求失败的处理逻辑
                        console.error(error);
                        alert(error);
                    });
                return axios.post(config.API_GPT_URL + 'del_thisRobot', {'robot_id': robot_id});
            }).catch(() => {
                // 用户取消删除
            })
        },
        //查询机器人图片库版本
        selectImageDBVersion(name) {
            const request = indexedDB.open("image-database");
            const that = this
            request.onsuccess = function (event) {
                const db = event.target.result;
                that.version = db.version;
                that.del_robotImgDB(name, db.version + 1)

                db.close(); // 关闭数据库连接
            };

            request.onerror = function (event) {
                console.error("无法打开数据库：", event.target.error);
            };
        },


        //删除机器人图片表
        del_robotImgDB(table, mode) {
            const request = indexedDB.open('image-database', mode);

            request.onupgradeneeded = function (event) {
                const db = event.target.result;
                if (db.objectStoreNames.contains(table)) {
                    db.deleteObjectStore(table);
                }
            };

            request.onsuccess = function (event) {
                const db = event.target.result;
                db.close();
            };

            request.onerror = function (event) {
                console.error('数据库打开失败:', event.target.errorCode);
            };
        },
        //搜索机器人
        robot_source() {
            // const type = parseInt(this.$store.state.base_msgs[this.user_id].chat_id) + 915035417 - 4582654 + 522565
            const navList = this.$store.getters.getNavs;
            this.base_msg = navList.slice(0, 11);
            // console.log(this.base_msg[9])
            // // 数据变更
            this.$store.state.base_msgs[this.user_id].page = this.base_msg[10].page;
            this.$store.state.base_msgs[this.user_id].title = this.base_msg[10].title;
            this.$store.state.base_msgs[this.user_id].url = this.base_msg[10].url;
            this.$store.state.robot_ids[this.user_id] = ''
            // //其他
            this.$store.state.titles[this.user_id] = this.base_msg[10].page;//更新标题名称
            this.updateMongodb(this.$store.state.users[this.user_id].id, this.$store.state.base_msgs[this.user_id], 'not_robotId')//更新用户数据
            //跳转
            this.$router.push({name: this.base_msg[10].url})
            // this.thisRobot = '';
            // this.$store.state.robot_ids[this.user_id] = '';
            // this.$store.state.base_msgs[this.user_id].robot_id = '';
            // this.updateMongodb(this.$store.state.users[this.user_id].id, this.$store.state.base_msgs[this.user_id], 'all');
        },
        //创建机器人
        robot_create() {
            const type = parseInt(this.$store.state.base_msgs[this.user_id].chat_id) + 915035417 - 4582654 + 522565
            const navList = this.$store.getters.getNavs;
            this.base_msg = navList.slice(0, 10);
            // 数据变更
            this.$store.state.base_msgs[this.user_id].page = this.base_msg[9].page;
            this.$store.state.base_msgs[this.user_id].chat_id = this.base_msg[9].chat_id;
            this.$store.state.titles[this.user_id] = this.base_msg[9].page;
            this.$store.state.base_msgs[this.user_id].title = this.base_msg[9].title;
            this.$store.state.base_msgs[this.user_id].url = this.base_msg[9].url;
            this.$store.state.robot_ids[this.user_id] = ''
            //其他
            this.$store.state.titles[this.user_id] = this.base_msg[9].page;//更新标题名称
            this.updateMongodb(this.$store.state.users[this.user_id].id, this.$store.state.base_msgs[this.user_id], 'not_robotId')//更新用户数据
            //跳转
            this.$router.push({name: this.base_msg[9].url, query: {type: type}})
        },
        //设置字数限制
        truncatedText(text, maxLength) {
            if (text) {
                if (text.length > maxLength) {
                    return text.substring(0, maxLength) + '...';
                }
            }
            return text;
        },
        //显示后端图片
        getFullImageUrl(url) {
            if (url) {
                return `${config.API_BASE_URL}${url}`;
            }
            // 假设后端的图片存储路径是 /static/images/，根据实际情况进行修改
        },
        checks() {
            this.modal.flag = !this.modal.flag;
        },
        //更新mongodb
        updateMongodb(id, data, type) {
            return axios.post(config.API_GPT_URL + 'update_userMsg', {'id': id, 'data': data, 'type': type});
        }
    }
}
</script>

<style scoped>
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css';
</style>
<style scoped lang="less">
.c_main {
  height: 100vh;
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  box-shadow: var(--bottom-shadow);
  border: var(--bottom-titleBorder);


}

.title {
  height: 15vh;
  border-bottom: 1px solid #A5A4A4;
}

.ravs-top {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5%;
}

.ravs-top .top-left {
  width: 32%;
  height: 8vh;
  cursor: pointer;
}


.ravs-top .head {
  max-width: 100%;
  max-height: 7vh;
  border-radius: 10px;

}

.ravs-top .top-msg {
  color: var(--white-color);
  width: 50%;
  word-wrap: break-word;
}

.top-msg .msg-title {
  font-size: 0.8vw;
  font-weight: bold;
  margin-bottom: 7%;
}


.ravs-top .bottom {
  margin-top: 3%;
}

.ravs-top .bottom button {
  //width: 340%;
  height: 3vh;
  border-radius: 20px;
  background-color: #ff3e3e;
  color: #fffafa;
  border: none;
}

.ravs-top .bottom button:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #ff0000;
}

.mmc-top {
  display: flex;
  flex-wrap: wrap;
  padding-left: 5%;
}

.mmc-top .mmc-title {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
  font-weight: bold;
  color: var(--white-color)

}

.mmc-top .bottom {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.mmc-top .bottom-theme {
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--white-color)

}

.mmc-top .bottom .el-button {
  border: none;
  width: 80%;
  background-color: #ff3e3e;
  color: #fffafa;
  border-radius: 20px;
}

.mmc-top .bottom .el-button:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #ff0000;
}

.Ravs-chat img {
  float: left;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.mmc-robot .change_theme {
  display: flex;
  flex-wrap: wrap;
  height: 6vh;
  width: 80%;
  background-color: var(--block-color);
  border-radius: 10px;
  padding: 5%;
  filter: brightness(1);
  box-shadow: var(--bottom-shadow);
  margin-bottom: 5%;
}


.mmc-robot .change_theme:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

.change_theme .robot-msg {
  width: 100%;
  display: flex;
}

.change_theme .robot-msg label {
  font-weight: bold;
}

.change_theme .robot-msg i {
  margin-left: auto;
}

.fa-exchange-alt {
  width: 100%;
}

.Ravs-chat .chat_room, .mmc_room {
  display: flex;
  width: 90%;
  cursor: pointer;
  height: 5vh;
  padding-left: 2%;
  align-items: center;
  border-bottom: var(--bottom-border);
}

.Ravs-chat .chat_room:hover, .mmc_room:hover {
  background-color: #c7c7c7;
}

.Ravs-chat {
  width: 100%;
  max-height: 85vh;
  color: var(--white-color);
  padding: 5%;
  overflow-y: auto;
}

.chat_room .msg, .mmc_room .msg {
  margin-left: 5%;
}


.Ravs-chat .robot-func {
  width: 90%;
  height: 10vh;
  display: flex;
  border-bottom: var(--bottom-border);
}

.robot-func .robot-source {
  margin-right: 5%;
}

.robot-func label {
  font-weight: bold;
}

.robot-func .robot-source,
.robot-func .robot-create {
  display: flex;
  flex-wrap: wrap;
  height: 6vh;
  width: 50%;
  background-color: var(--block-color);
  box-shadow: var(--bottom-shadow);
  border-radius: 10px;
  padding: 5%;
  filter: brightness(1);
}

.robot-func .robot-source:hover,
.robot-func .robot-create:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

.fa-search, .fa-robot {
  width: 100%;
}

.robot-func .robot-msg {
  width: 100%;
  display: flex;
  align-items: center;
}

.robot-func .robot-msg i {
  margin-left: auto;
}


/*------------------------手机平板通用------------------------------*/
@media screen and (max-width: 899px) {
  .c_main {
    height: 120vh;
    box-sizing: border-box;
    background-color: var(--block-color);
  }

  .top-msg .msg-title {
    margin-bottom: 2%;
  }


  .robot-func .robot-source,
  .robot-func .robot-create {
    border: var(--bottom-border);
  }

  .robot-msg i {
    font-size: 2.5vw;
  }

  .mmc-top .bottom .el-button {
    height: 3vh;
  }

  .Ravs-chat .mmc_room {
    padding: 0;
  }

  .Ravs-chat .chat_room {
    padding-top: 2%;
    padding-bottom: 1%;
  }

  .mmc-robot .change_theme {
    border: var(--bottom-border);
  }


  .mmc_room .msg {
    margin-left: 0;
  }
}

/*------------------------手机------------------------------*/
@media screen and (max-width: 767px) {
  .ravs-top .bottom button {
    width: 300%;
  }

  .chat_room .msg, .mmc_room .msg {
    font-size: 3.5vw;
    margin-left: 5%;
  }

  .top-msg .msg-title {
    font-size: 5.2vw;
  }

  .ravs-top .top-content {
    font-size: 4vw;
  }

  .robot-func label {
    font-size: 3vw;
  }

  .fa-search, .fa-robot {
    font-size: 5.5vw;
  }

  .change_theme .robot-msg label {
    font-size: 4vw;
  }

  .change_theme .robot-msg i {
    font-size: 5vw;
  }

  .fa-exchange-alt {
    font-size: 6vw;
  }

  .mmc-top .bottom-theme, .mmc-top .mmc-title {
    font-size: 4vw;
  }

  .mmc-top .bottom .el-button {
    margin-top: 3%;
    font-size: 3.5vw;
  }
}

/*------------------------平板------------------------------*/
@media screen and (min-width: 768px) and (max-width: 899px) {
  .bottom button {
    width: 350%;
  }

  .ravs-top .head {
    max-height: 8vh;
  }

  .top-msg .msg-title {
    font-size: 3vw;
  }

  .ravs-top .top-content {
    font-size: 2.4vw;
  }

  .robot-func .robot-msg {
    margin-top: 8%;
  }

  .robot-func label {
    font-size: 1.5vw;
  }

  .fa-search, .fa-robot {
    font-size: 4vw;
  }

  .fa-exchange-alt {
    font-size: 4vw;
  }

  .change_theme .robot-msg i {
    font-size: 3vw;
  }

  .change_theme .robot-msg label {
    font-size: 2vw;
  }

  .change_theme .robot-msg {
    margin-top: 4%;
  }

  .mmc-top .bottom .el-button {
    margin-top: 3%;
  }

  .mmc-top .bottom-theme, .mmc-top .mmc-title {
    font-size: 2.5vw;
  }

  .mmc-top .bottom .el-button {
    font-size: 2vw;
  }
}

/*------------------------小电脑------------------------------*/
@media screen and (min-width: 900px) and (max-width: 1119px) {
  .bottom button {
    width: 280%;
  }

  .ravs-top .top-msg {
    margin-left: 3%;
  }

  .ravs-top .top-content {
    font-size: 1.4vw;
  }

  .top-msg .msg-title {
    font-size: 1.6vw;

  }

  .fa-search, .fa-robot {
    font-size: 2.2vw;
  }

  .robot-func label, .robot-func .robot-msg i {
    font-size: 0.9vw;
  }

  .chat_room .msg {
    font-size: 1vw;
  }

  .Ravs-chat .chat_room, .mmc_room {
    height: 6vh;
  }

  .change_theme .robot-msg label {
    font-size: 1.3vw;
  }

  .fa-exchange-alt {
    font-size: 2.1vw;
  }

  .chat_room .msg, .mmc_room .msg {
    font-size: 1.15vw;
  }

  .mmc-top .mmc-title {
    font-size: 1.8vw;
  }

  .mmc-top .bottom-theme {
    height: 4vh;
    font-size: 1.5vw;
  }

  .mmc-top .bottom .el-button {
    height: 24px;
  }

}

/*------------------------中电脑------------------------------*/
@media screen and (min-width: 1200px) and (max-width: 2199px) {
  .bottom button {
    width: 290%;
  }

  .ravs-top .top-content {
    font-size: 1.1vw;
  }

  .top-msg .msg-title {
    font-size: 1.2vw;

  }

  .fa-search, .fa-robot {
    font-size: 1.5vw;
  }

  .robot-func label {
    font-size: 0.7vw;
  }

  .chat_room .msg {
    font-size: 1vw;
  }

  .chat .chat_room, .mmc_room {
    height: 6vh;
  }

  .fa-exchange-alt {
    font-size: 1.8vw;
  }

  .change_theme .robot-msg label {
    font-size: 1vw;
  }

  .mmc-top .mmc-title {
    font-size: 1.3vw;
  }

  .mmc-top .bottom-theme {
    height: 4vh;
    font-size: 1.1vw;
  }

  .mmc-top .bottom .el-button {
    height: 25px;
  }

}

/*------------------------大电脑------------------------------*/
@media screen and (min-width: 2200px) {
  .ravs-top .bottom button {
    width: 320%;
  }

  .fa-search, .fa-robot {
    font-size: 1vw;
  }

  .robot-func label {
    font-size: 0.5vw;
  }

  .ravs-top .top-content {
    font-size: 0.6vw;
  }

  .chat_room .msg {
    font-size: 0.6vw;
  }

  .change_theme .robot-msg label {
    font-size: 0.6vw;
  }

  .fa-exchange-alt {
    font-size: 0.9vw;
  }

  .mmc-top .mmc-title {
    font-size: 0.7vw;
  }

  .mmc-top .bottom-theme {
    height: 5vh;
    font-size: 0.62vw;
  }
}
</style>
