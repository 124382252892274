<script setup>

</script>

<template>
  <router-view ></router-view>
</template>



<style lang="less">
* {
  margin: 0;
  padding: 0;
}

</style>

