import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus' //全局引入
import 'element-plus/dist/index.css'
// import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';

import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
import Prism from 'prismjs';
import zhLocale from 'element-plus/es/locale/lang/zh-cn';
// @ts-ignore
import versionTood from '../src/static/js/versionUpdate.js'

// import hljs from 'highlight.js';

router.beforeEach((to, from, next) => {

    //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新

    versionTood.isNewVersion();
    next();
})

VMdPreview.use(vuepressTheme, {
    Prism,
})

VMdPreview.use(createCopyCodePlugin());

// 添加导航守卫
// router.beforeEach(async (to, from, next) => {
//     // 从会话存储中获取数据
//
//     console.log(123);
//     if (to.path !== '/login') {
//         router.push('/login');
//     }
//     next()
// });


const app = createApp(App);


app.use(VMdPreview);
app.use(store).use(router).mount('#app');
app.use(ElementPlus, { locale: zhLocale });
// app.use(ElementPlus, { locale: zhLocale }).mount('#app');

