import {createStore} from 'vuex'


export default createStore({
    state: {
        page: "MMCCHAT",
        base_msgs: {},
        users: {},
        userFinance: {},
        robot_ids: {},
        titles: {},
        newsource: {},
        isChattting: {},
        navs: [
            {
                chat_id: '10000',
                page: "mmcInteract",
                head: 'avatar/robot/chatGPTAvatar.png',
                title: "多智协作",
                sty: "nav",
                // type: "chat_mmc",
                robot: 'mmc_interact',
                url: "mmcSetting"
            },
            {
                chat_id: '10001',
                page: "mmcChat",
                head: 'avatar/robot/chatGPTAvatar.png',
                title: "垂直交互",
                sty: "nav",
                // type: "gpt-3.5-turbo",

                robot: 'mmc_chat',
                url: "chat",

            },
            {
                chat_id: '11111',
                page: "AI试卷",
                head: 'avatar/robot/chatGPTAvatar.png',
                title: "AI试卷",
                sty: "nav",
                // type: "gpt-4",
            },
            {
                page: "MMC-DOCS",
                head: require("@/assets/img/chatGPTAvatar.png"),
                title: "自动化控制",
                sty: "nav active",
                type: "gpt-3.5-turbo-docs",
                robot: '',
                url: "ceshi",
            },
            {
                chat_id: '1',
                page: "AOTUGPT",
                head: require("@/assets/svg/setting.svg"),
                title: "订阅",
                sty: "nav",
                url: "ceshi"
            },
            {
                chat_id: '2',
                page: "充值",
                head: require("@/assets/svg/setting.svg"),
                title: "充值",
                sty: "nav",
                url: "Recharge_balance"
            },
            {
                chat_id: '3',
                page: "个人",
                head: require("@/assets/svg/setting.svg"),
                title: "个人",
                sty: "nav",
                url: "person_basemsg"
            },
            {
                chat_id: '4',
                page: "设置",
                head: require("@/assets/svg/setting.svg"),
                title: "设置",
                sty: "nav",
                url: "settings"
            },
            {
                chat_id: '5',
                page: "机器人信息",
                head: require("@/assets/svg/setting.svg"),
                title: "机器人信息",
                sty: "nav",
                url: "robot_baseMsg"
            },
            {
                chat_id: '6',
                page: "创建机器人",
                head: require("@/assets/svg/setting.svg"),
                title: "创建机器人",
                sty: "nav",
                url: "robot_create"
            },
            {
                page: "搜索机器人",
                head: require("@/assets/svg/setting.svg"),
                title: "搜索机器人",
                sty: "nav",
                url: "robot_search"
            },
            {
                page: "请使用浏览器打开",
                head: require("@/assets/svg/setting.svg"),
                title: "请使用浏览器打开",
                sty: "nav",
                url: "wx_promp"
            },
            {
                chat_id: '11111',
                page: "主 页",
                head: 'avatar/robot/chatGPTAvatar.png',
                title: "主 页",
                sty: "nav",
                // type: "gpt-4",
                url: "index"
            },

        ],
    },
    getters: {
        getNavs(state) {
            try {
                if (localStorage.getItem("navs") === null) {
                    return state.navs;
                } else if (JSON.parse(<string>localStorage.getItem("navs")).length === 0) {
                    return state.navs;
                } else {
                    state.navs = JSON.parse(<string>localStorage.getItem("navs"));
                }
            } catch (err) {
                return state.navs;
            }
            return state.navs;

        },

    }
    ,
    actions: {}
    ,
    modules: {}
})
