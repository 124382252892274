// const API_BASE_URL = 'http://127.0.0.1:3000/';//后端接口javascript:;
// const API_GPT_URL = 'http://127.0.0.1:5000/'
const API_BASE_URL = 'https://voice.mmcgpt.com/';//后端接口
const API_GPT_URL = 'https://mmcchatapi.mmcgpt.com/';
export default {
    API_BASE_URL,
    API_GPT_URL
};
// 配置文件
// location /
// {
//     try_files $uri $uri / /index.html;
// }