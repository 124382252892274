import axios from 'axios'


const isNewVersion = () => {
    let url = `//${window.location.host}/static/version.json?t=${new Date().getTime()}`;
    window.onload = function () {
        axios.get(url).then(res => {
            if (res.status === 200) {
                let vueVersion = res.data.version;
                let localVueVersion = localStorage.getItem('vueVersion');
                if (localVueVersion && localVueVersion !== vueVersion) {
                    window.location.reload(true);
                    localStorage.setItem('vueVersion', vueVersion);
                } else {
                    localStorage.setItem('vueVersion', vueVersion);
                }

            }

        });
    }
    document.addEventListener('click', function (event) {
        axios.get(url).then(res => {
            if (res.status === 200) {
                let vueVersion = res.data.version;
                let localVueVersion = localStorage.getItem('vueVersion');
                if (localVueVersion && localVueVersion !== vueVersion) {
                    window.location.reload(true);
                    localStorage.setItem('vueVersion', vueVersion);
                } else {
                    localStorage.setItem('vueVersion', vueVersion);
                }

            }

        });
    });

}
export default {
    isNewVersion
}




