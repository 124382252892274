<template>
    <div class="n_main">
        <div class="navs-title" @click="jumpToHomePage()">
            <img class="head" src="../../../public/img/logo.png" alt="">
            <p>毛毛虫智能交互</p>
        </div>

        <div class="navs">
            <div class="body">
                <div v-for="(i,index) in chat_api" :key="index">
                    <div class="chat_api" ref="nav" @click="check_Chat(index)"
                         :class="{ backgroundModle: pageColor === i.page }">
                        <div>
                            {{ i.title }}
                        </div>
                    </div>
                </div>
                <div class="navs-func">
                    <div v-for="(i,index) in base_msg" :key="index" class="base_msg">
                        <div class="chat_api" ref="nav" @click="check_Setting(index)"
                             :class="{ backgroundModle: pageColor === i.page}">
                            <div>
                                {{ i.title }}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>


import axios from "axios";
import config from "@/api/api";
import {watch} from "vue";

export default {
    name: "Navs",
    created() {
        watch(() => this.$store.state.titles[localStorage.getItem('token')], () => {
            // 颜色变更
            this.pageColor = this.$store.state.titles[localStorage.getItem('token')]
        });
        this.user_id = localStorage.getItem('token')

        const navList = this.$store.getters.getNavs;
        this.chat_api = navList.slice(0, 3);
        this.base_msg = navList.slice(5, 8);
        this.chatIndex = navList.slice(navList.length - 1, navList.length);
    },
    data() {
        return {
            user_id: '',
            pageColor: '',
            navList: [],
            showModal: false,

        };
    },
    methods: {
        //更新mongodb数据库
        updateMongodb(id, data, type, url) {
            return axios.post(config.API_GPT_URL + 'update_userMsg', {'id': id, 'data': data, 'type': type})
                .then(response => {
                    // 请求成功的处理逻辑
                    if (response.data.state === 1) {
                        this.$router.push({name: url})
                    }
                })

        },
        jumpToHomePage() {
            this.$store.state.base_msgs[this.user_id].page = this.chatIndex[0].page;
            this.$store.state.base_msgs[this.user_id].head = this.chatIndex[0].head;
            this.$store.state.base_msgs[this.user_id].title = this.chatIndex[0].title;
            this.$store.state.base_msgs[this.user_id].chat_id = this.chatIndex[0].chat_id;
            this.$store.state.base_msgs[this.user_id].url = this.chatIndex[0].url;
            this.$store.state.titles[localStorage.getItem('token')] = this.chatIndex[0].page;//更新标题名称
            this.$store.state.robot_ids[this.user_id] = ''
            this.updateMongodb(this.$store.state.users[this.user_id].id, this.$store.state.base_msgs[this.user_id], 'not_robotId', this.chatIndex[0].url)//更新用户数据
            //页面跳转
            // this.$router.push({name: this.chatIndex[0].url})
        },
        //实现点击聊天功能切换
        check_Chat(event) {
            if (this.chat_api[event].page === 'AI试卷') {
                window.open('test', '_blank');
            } else {
                this.$store.state.base_msgs[this.user_id].page = this.chat_api[event].page;
                this.$store.state.titles[localStorage.getItem('token')] = this.chat_api[event].page;//更新标题名称
                this.$store.state.base_msgs[this.user_id].head = this.chat_api[event].head;
                this.$store.state.base_msgs[this.user_id].title = this.chat_api[event].title;
                this.$store.state.base_msgs[this.user_id].chat_id = this.chat_api[event].chat_id;
                // this.$store.state.base_msgs[this.user_id].type = this.chat_api[event].type;
                this.$store.state.base_msgs[this.user_id].url = this.chat_api[event].url;
                this.$store.state.base_msgs[this.user_id].robot = this.chat_api[event].robot;
                this.update_robotId(this.$store.state.users[this.user_id].id, this.chat_api[event].robot, this.chat_api[event].url)//获取机器人id


            }

        },
        check_Setting(event) {

            // 数据变更

            this.$store.state.base_msgs[this.user_id].page = this.base_msg[event].page;
            this.$store.state.base_msgs[this.user_id].chat_id = this.base_msg[event].chat_id;
            this.$store.state.base_msgs[this.user_id].title = this.base_msg[event].title;
            this.$store.state.base_msgs[this.user_id].url = this.base_msg[event].url;
            this.$store.state.robot_ids[this.user_id] = ''
            //其他
            this.$store.state.titles[localStorage.getItem('token')] = this.base_msg[event].page;//更新标题名称


            this.updateMongodb(this.$store.state.users[this.user_id].id, this.$store.state.base_msgs[this.user_id], 'not_robotId', this.base_msg[event].url)//更新用户数据
            //跳转
            // this.$router.push({name: this.base_msg[event].url})


        },
        checks() {
            this.modal.flag = !this.modal.flag;
        },
        //获取对应的robot.id
        update_robotId(user_id, robot, url) {
            return axios.post(config.API_GPT_URL + 'get_userMsg', {
                'type': 'robot_id',
                'user_id': user_id,
                'robot': robot
            })
                .then(response => {
                    // 请求成功的处理逻辑
                    if (response.data.state === 'success') {
                        this.$store.state.robot_ids[this.user_id] = response.data.robot_id
                        this.$store.state.base_msgs[this.user_id].robot_id = response.data.robot_id
                        this.updateMongodb(this.$store.state.users[this.user_id].id, this.$store.state.base_msgs[this.user_id], 'not_robotId', url)//更新用户数据
                    }
                })
                .catch(error => {
                    // 请求失败的处理逻辑
                    console.error(error);
                    alert(error);
                })
                .finally(() => {
                });
        }
    },
}
</script>

<style scoped lang="less">
/*------------------------基本元素------------------------------*/
.n_main {
  height: 100vh;
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  box-shadow: var(--bottom-shadow);
  border-right: var(--bottom-titleBorder);
  color: var(--white-color);
}

.navs-title {
  height: 10vh;
  width: 100%;
  display: flex;
  align-items: center; /* 将内容垂直居中 */
  font-weight: 600;
  cursor: pointer;
  border-bottom: 1px solid #A5A4A4;
}

.navs-title p {
  margin-left: 10px;
}

.navs-title .head {
  width: 50px;
  height: 50px;
  border-radius: 50%;

}

//设置接口样式
.chat_api {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 8vh;
  border-bottom: 1px solid #A5A4A4;
  //字体
  //color: #A6A5A5; /* 设置颜色为 #A6A5A5 */
  font-size: 20px;
  font-family: Georgia, serif; /* 设置字体为 Helvetica 或 Arial，如果不可用则使用 sans-serif 作为备选字体 */
  font-weight: bold; /* 加粗 */
  transition: background-color 0.3s, color 0.3s; /* 添加过渡效果 */
}

.chat_api:hover {
  cursor: pointer;
  background-color: #C0CBFA;
  color: #688DF8;
}

.navs {
  height: calc(99vh - 53px);
  overflow-y: auto;

}

/*修改滚动条样式*/
.navs::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

.navs::-webkit-scrollbar-track {
  background: var(--body-color);
  border-radius: 2px;
}

.navs::-webkit-scrollbar-thumb {
  background: var(--thumb);
}

.body {
  width: 100%;

}

.nav {
  height: 45px;
  line-height: 45px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--body-color);
}


.nav:hover {
  background-color: var(--title-color);

}

.nav:active {
  background-color: var(--active-color);
}

.active {
  background-color: var(--active-color);
}

.active:hover {
  background-color: var(--active-hover);
}

.navs-func {
  border-top: 1px solid rgba(161, 154, 154, 0.5);
}

.navs-title img {
  float: left;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border-radius: 50%;

}


.modal {
  display: flex;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  margin: auto;
  background-color: var(--body-color);
  border: 1px solid #888;
  padding: 20px;
  width: 60%;
}

.modal-head {
  height: 30px;
  border-bottom: 2px solid var(--active-hover);
}

.modal-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-top: -30px;
}

.modal-close:hover,
.modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-nav {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--title-color);
}

.modal-nav label {
  width: 120px;
  text-align: left;
}

.modal-nav input {
  width: 100%;
  height: 30px;
  border: 1px solid var(--active-hover);
  text-indent: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: var(--color);
}

input:focus {
  outline: 1px solid var(--active-hover);

}

.foot_content {

}

.foot_btn {
  margin-top: 20px;
  text-align: right;
}

.btn {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  float: right;
}


.custom-select {
  position: relative;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  user-select: none;
  width: 100%;
}

.select-toggle {
  cursor: pointer;
  font-size: 16px;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  border: 1px solid #ccc;
  background-color: var(--body-color);
  color: var(--color);
  border-radius: 4px;
  overflow: hidden;
  max-height: 200px;
  transition: max-height 0.3s ease-in-out;
  width: 100%;
}

.select-option {
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px solid var(--title-color);
}

.select-option:hover {
  background-color: var(--title-color);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.backgroundModle {
  background-color: #C0CBFA;
  color: #688DF8;
}

/*------------------------手机------------------------------*/
@media screen and (max-width: 768px) {
  .backgroundModle {
    background-color: #C0CBFA;
    color: #688DF8;
  }

  .n_main {
    height: 100vh;
    //width: 248px;
    box-sizing: border-box;
    border: 0.5px solid var(--title-color);
    background-color: var(--block-color);
    //border-right: 1px solid #A5A4A4;
    backdrop-filter: blur(20px);
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.3);
    color: var(--white-color);
  }

  .body {
    width: 100%;
    height: calc(100vh - 10vh);
    overflow-y: auto;
    //height: 200px; /* 设置导航栏可见区域的高度 */

  }


  .chat_api {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 8vh;
    border-bottom: 1px solid rgba(161, 154, 154, 50%);
    font-size: 4vw;
    font-weight: bold; /* 加粗 */
    transition: background-color 0.3s, color 0.3s; /* 添加过渡效果 */
  }

  .navs-func {
    margin-top: 180%;
  }

  .navs-title {
    font-size: 4vw;
    border-bottom: 1px solid rgba(161, 154, 154, 50%);
  }

  .navs-title .head {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  //设置滚动条透明
  .body::-webkit-scrollbar {
    width: 0.1rem; /* 设置滚动条宽度 */
    background-color: transparent; /* 将滚动条背景设为透明 */
  }

}

/*------------------------平板------------------------------*/
@media screen and (min-width: 768px) and (max-width: 899px) {
  .navs-func {
    margin-top: 150%;
  }

  .chat_api {
    font-size: 22px;
  }

  .navs-title {
    font-size: 2.6vw;
  }
}

/*------------------------小电脑------------------------------*/
@media screen and (min-width: 900px) and (max-width: 1119px) {
  .navs-func {
    margin-top: 180%;
  }

  .navs-title {
    font-size: 1.6vw;
  }

  .navs-title .head {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .chat_api {
    font-size: 17px;
  }
}

/*------------------------中电脑------------------------------*/
@media screen and (min-width: 1200px) and (max-width: 2199px) {
  .navs-func {
    margin-top: 160%;
  }

  .navs-title {
    font-size: 1.3vw;
  }
}

/*------------------------大电脑------------------------------*/
@media screen and (min-width: 2200px) {
  .navs-func {
    margin-top: 180%;
  }

  .navs-title {
    font-size: 0.8vw;
  }
}


</style>