<template>
    <div>
        <el-dialog v-model="showModal" @close="resetForm"
                   :close-on-click-modal="false"
                   :show-close="false"
                   custom-class="my-dialog">
            <div class="edit-msg" v-if="isExitMsg">
                <label class="person-title">注册个人信息</label>
                <img :src="previewUrl" class="update-avatar-preview" @click="control_page(false)">
                <div class="exit-imgFont" @click="control_page(false)">编辑图片</div>
                <div class="nickname">
                    <label for="nickname-input">昵称</label>
                    <input v-model="nickname" id="nickname-input" placeholder="请输入昵称" maxlength="10">
                    <span>{{ characterCount }}/10</span>
                </div>
                <el-button @click="uploadAvatar" class="determine-button">确认</el-button>
            </div>
            <div class="edit-img" v-else>
                <div class="img-title">
                    <i class="fas fa-angle-left" @click="control_page(true)"></i>
                    <label class="img-titleFont" @click="control_page(true)">编辑个人信息</label>
                    <div class="change-img" @click="get_images">
                        <i class="fas fa-sync-alt"></i>
                        <label>换一换</label>
                    </div>
                </div>
                <div class="img-avatar">
                    <img :src="previewUrl" class="update-avatar-preview">
                    <input type="file" ref="fileInput" style="display: none" @change="handleFileChange">
                    <button style="background-color: #494949" @click="openFileInput">从本地文件选择</button>
                    <button style="background-color: #5958D5" @click="control_page(true)">保存</button>
                </div>
                <div class="avatarLibrary">
                    <div v-for="(i, index) in imageGallery" :key="index" class="avatarLibrary-avatar"
                         @click="get_avator(i.images)">
                        <img :src="getFullImageUrl(i.images)" alt="">
                    </div>

                </div>


            </div>

            <!--            <div class="input-container">-->
            <!--                            <el-input v-model="nickname" id="nickname" placeholder="请输入昵称" class="nickname-input"></el-input>-->

            <!--            </div>-->

        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import config from '../../api/api';
import {ElMessageBox} from 'element-plus';

export default {
    data() {
        return {
            token: localStorage.getItem('token'),
            showModal: false,
            file: null,
            nickname: '',
            characterCount: 0,
            isExitMsg: true,
            imageGallery: [],
            isAvatarLibrary: false,
            defaultAvatar: 'Images/icon/add_header.png',
            Avatar: 'Images/icon/add_header.png',
        };
    },
    watch: {
        nickname(value) {
            this.characterCount = value.length;
            if (this.characterCount > 10) {
                this.nickname = value.slice(0, 10);
                this.characterCount = 10;
            }
        }
    },
    created() {
        this.get_images()
    },
    computed: {
        previewUrl() {
            return this.file ? URL.createObjectURL(this.file) : this.getFullImageUrl(this.Avatar);
        }
    },
    mounted() {
        this.showModal = true; // 自动弹出模态框
    },
    methods: {

        openFileInput() {
            this.$refs.fileInput.click(); // 打开文件选择对话框
        },
        handleFileChange(event) {
            this.file = event.target.files[0]; // 获取选择的文件
            if (this.file) {
                if ((this.file && this.file.type.startsWith('image/'))) {
                    this.isAvatarLibrary = false
                    this.previewUrl = URL.createObjectURL(this.file); // 生成预览URL
                } else {
                    this.isAvatarLibrary = false
                    this.file = null
                    this.Avatar = this.defaultAvatar
                    ElMessageBox.alert('请选择有效的图片文件！', '错误提示', {
                        type: 'error'
                    });
                }

            }
        },
        //上传图片至后端
        uploadAvatar() {
            if ((this.file || this.isAvatarLibrary) && this.nickname) {
                const formData = new FormData();
                formData.append('avatarFile', this.file);
                formData.append('nickname', this.nickname);
                formData.append('isAvatarLibrary', this.isAvatarLibrary);
                formData.append('Avatar', this.Avatar)
                formData.append('token', this.token)
                //
                axios.post(config.API_BASE_URL + 'front/uplode_msg', formData)
                    .then(response => {
                        //模态框消失
                        this.showModal = false
                        location.reload();
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                ElMessageBox.alert('请上传头像并且填入昵称！', '错误提示', {
                    type: 'error'
                });

            }
        },
        resetForm() {
            this.file = null;
            this.nickname = '';
        },
        //获取图片库的图片
        get_images() {
            axios.post(config.API_BASE_URL + 'front/getIMages', {screen_size: window.innerWidth})
                .then(response => {
                    // 请求成功的处理逻辑
                    this.imageGallery = response.data.images
                })
                .catch(error => {
                    // 请求失败的处理逻辑
                    console.error(error);
                    alert(error);
                });
        },
        // 控制图片编辑页面
        control_page(type) {
            this.isExitMsg = type
        },
        get_avator(image_url) {
            this.file = null;
            this.isAvatarLibrary = true
            this.Avatar = image_url
        },
        //显示后端图片
        getFullImageUrl(url) {
            if (url) {
                return `${config.API_BASE_URL}${url}`;
            }
            // 假设后端的图片存储路径是 /static/images/，根据实际情况进行修改

        },
    }
};
</script>

<style>
.my-dialog {
    display: flex;
    justify-content: center;
    height: 38vh !important;
    width: 20% !important;
    border-radius: 20px !important;
}

.el-dialog__header {
    display: none;
}

.el-dialog__body {
    width: 100%;
    height: 35vh;
}

/*信息编辑页面*/
.edit-msg {
    width: 100%;
    height: 35vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.person-title {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 0.7vw;
    font-weight: bold;
}


.exit-imgFont {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: -2%;
    color: #4342c1;
    font-size: 0.6vw;
}


.nickname {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nickname label {
    width: 15%;
    font-size: 0.6vw;
}

.nickname input {
    width: 60%;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    font-size: 0.6vw;
}

.nickname input:focus {
    border-bottom: 1px solid blue;
}

.determine-button {
    border-radius: 20px !important;
    width: 30% !important;
    height: 4vh !important;
    color: white !important;
    background-color: #7474f4 !important;
}

.error-message {
    display: flex;
    color: red;
    width: 100%;
    justify-content: center;
}

/*图片选择库页面*/
.edit-img {
    width: 100%;
    height: 35vh;
    display: flex;
    flex-wrap: wrap;
}

.edit-img .img-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 2vh;

}

.edit-img .img-titleFont {
    cursor: pointer;
}

.edit-img .fa-angle-left {
    margin-right: 2%;
    cursor: pointer;
    font-size: 1vw;
}

.change-img {
    width: 20%;
    margin-left: auto;
    cursor: pointer;
}

.change-img i, .change-img label {
    cursor: pointer;
}

.edit-img .img-avatar {
    width: 40%;
    margin-top: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}


.update-avatar-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-avatar button {
    cursor: pointer;
    margin-top: -20%;
    border-radius: 10px;
    height: 3vh;
    border: none;
    color: white;
}

.avatarLibrary {
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid #c4c4c4;
    width: 55%;
    height: 30vh;
    padding: 2%;

}

.avatarLibrary-avatar {
    width: 30%;
    margin-right: 3%;
}

.avatarLibrary-avatar img {
    border-radius: 50%;
    border: 1px solid #e4e4e4;
    max-width: 100%;
    min-width: 100%;

}

@media screen and (max-width: 768px) {
    .my-dialog {
        width: 90% !important;
    }

    .person-title {
        font-size: 4vw;
    }

    .exit-imgFont,
    .nickname input,
    .nickname label {
        font-size: 3.5vw;
    }


    .avatarLibrary {
        width: 55%;
        height: 29vh;
    }

    .edit-img .fa-angle-left {
        font-size: 4vw;
    }

    .change-img i,
    .change-img label,
    .edit-img .img-titleFont {
        font-size: 3.5vw;
    }

    .img-avatar button {
        width: 80%;
        font-size: 3vw;
    }

    .avatarLibrary-avatar {
        width: 40%;
        margin-right: 5%;
        margin-left: 5%;
        margin-top: 5%;
    }


}

.img-avatar .update-avatar-preview {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    background-color: #ccc;
}

.edit-msg .update-avatar-preview {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    background-color: #ccc;
}

/*------------------------手机------------------------------*/
@media screen and (max-width: 767px) {
    .img-avatar .update-avatar-preview {
        max-width: 65%;
        min-width: 65%;
        min-height: 10vh;
        max-height: 10vh;
    }

    .edit-msg .update-avatar-preview {
        max-width: 28%;
        min-width: 28%;
        min-height: 10vh;
        max-height: 10vh;
    }
}

/*------------------------平板------------------------------*/
@media screen and (min-width: 768px) and (max-width: 899px) {
    .edit-msg .update-avatar-preview {
        min-height: 12vh;
        max-height: 12vh;
        max-width: 25%;
        min-width: 25%;
        margin-top: 5%;
    }
}

/*------------------------小电脑------------------------------*/
@media screen and (min-width: 900px) and (max-width: 1119px) {
    .edit-msg .update-avatar-preview {
        max-width: 25%;
        min-width: 25%;
        min-height: 10vh;
        max-height: 10vh;
    }

    .img-avatar .update-avatar-preview {
        max-width: 56%;
        min-width: 56%;
        min-height: 10vh;
        max-height: 10vh;
    }
}

/*------------------------中电脑------------------------------*/
@media screen and (min-width: 1200px) and (max-width: 2199px) {
    .edit-msg .update-avatar-preview {
        max-width: 23%;
        min-width: 23%;
        min-height: 10vh;
        max-height: 10vh;
    }

    .img-avatar .update-avatar-preview {
        max-width: 60%;
        min-width: 60%;
        min-height: 10vh;
        max-height: 10vh;
    }
}

.img-avatar .update-avatar-preview {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    background-color: #ccc;
}

.edit-msg .update-avatar-preview {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    background-color: #ccc;
}

/*------------------------手机------------------------------*/
@media screen and (max-width: 767px) {
    .img-avatar .update-avatar-preview {
        max-width: 65%;
        min-width: 65%;
        min-height: 10vh;
        max-height: 10vh;
    }

    .edit-msg .update-avatar-preview {
        max-width: 28%;
        min-width: 28%;
        min-height: 10vh;
        max-height: 10vh;
    }
}

/*------------------------平板------------------------------*/
@media screen and (min-width: 768px) and (max-width: 899px) {
    .img-avatar .update-avatar-preview {
        max-width: 53%;
        min-width: 53%;
        min-height: 10vh;
        max-height: 10vh;
    }
    .edit-msg .update-avatar-preview {
        min-height: 12vh;
        max-height: 12vh;
        max-width: 25%;
        min-width: 25%;
        margin-top: 5%;
    }
}

/*------------------------小电脑------------------------------*/
@media screen and (min-width: 900px) and (max-width: 1119px) {
    .edit-msg .update-avatar-preview {
        position: relative;
        display: inline-block;
        max-width: 25%;
        min-width: 25%;
        min-height: 10vh;
        max-height: 10vh;
        border-radius: 50%;
        overflow: hidden;
        background-color: #ccc;
    }

    .img-avatar .update-avatar-preview {
        position: relative;
        display: inline-block;
        max-width: 58%;
        min-width: 58%;
        min-height: 10vh;
        max-height: 10vh;
        border-radius: 50%;
        overflow: hidden;
        background-color: #ccc;
    }
}

/*------------------------中电脑------------------------------*/
@media screen and (min-width: 1200px) and (max-width: 2199px) {
    .edit-msg .update-avatar-preview {
        position: relative;
        display: inline-block;
        max-width: 23%;
        min-width: 23%;
        min-height: 10vh;
        max-height: 10vh;
        border-radius: 50%;
        overflow: hidden;
        background-color: #ccc;
    }

    .img-avatar .update-avatar-preview {
        position: relative;
        display: inline-block;
        max-width: 60%;
        min-width: 60%;
        min-height: 10vh;
        max-height: 10vh;
        border-radius: 50%;
        overflow: hidden;
        background-color: #ccc;
    }
}

/*------------------------大电脑------------------------------*/
@media screen and (min-width: 2200px) {
    .edit-msg .update-avatar-preview {
        position: relative;
        display: inline-block;
        max-width: 21%;
        min-width: 21%;
        min-height: 10vh;
        max-height: 10vh;
        border-radius: 50%;
        overflow: hidden;
        background-color: #ccc;
    }

    .img-avatar .avatar-preview {
        position: relative;
        display: inline-block;
        max-width: 53%;
        min-width: 53%;
        min-height: 10vh;
        max-height: 10vh;
        border-radius: 50%;
        overflow: hidden;
        background-color: #ccc;
    }
}
</style>
