import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

import ChatDex from "@/views/chat/ChatDex.vue";
import axios from "axios";
// @ts-ignore
import config from "@/api/api"
import store from '@/store'; // 导入 Vuex stor

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: ChatDex,
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import(/* webpackChunkName: "about" */ '../views/chat/chatIndex.vue')
            },
            {
                path: '/chat',
                name: 'chat',
                component: () => import(/* webpackChunkName: "about" */ '../views/chat/Chat.vue')
            },
            {
                path: '/mmcSetting',
                name: 'mmcSetting',
                component: () => import(/* webpackChunkName: "about" */ '../views/chat/mmcSetting.vue')
            },
            {
                path: '/mmcChat',
                name: 'mmcChat',
                component: () => import(/* webpackChunkName: "about" */ '../views/chat/mmcChat.vue')
            },
            {
                path: '/settings',
                name: 'settings',
                component: () => import(/* webpackChunkName: "about" */ '../views/account/settings.vue')
            },
            {
                path: '/person_basemsg',
                name: 'person_basemsg',
                component: () => import(/* webpackChunkName: "about" */ '../views/account/person_basemsg.vue')
            },
            {
                path: '/Recharge_balance',
                name: 'Recharge_balance',
                component: () => import(/* webpackChunkName: "about" */ '../views/account/Recharge_balance.vue')
            },
            {
                path: '/expenses_record',
                name: 'expenses_record',
                component: () => import(/* webpackChunkName: "about" */ '../views/account/expenses_record.vue')
            },
            {
                path: '/robot_baseMsg',
                name: 'robot_baseMsg',
                component: () => import(/* webpackChunkName: "about" */ '../views/robot/base_msg.vue')
            },
            {
                path: '/robot_create',
                name: 'robot_create',
                component: () => import(/* webpackChunkName: "about" */ '../views/robot/robot_create.vue')
            },
            {
                path: '/robot_search',
                name: 'robot_search',
                // component: () => import(/* webpackChunkName: "about" */ '../views/ceshi.vue')
                component: () => import(/* webpackChunkName: "about" */ '../views/robot/Search_robot.vue')
            },
            {
                path: '/wx_promp',
                name: 'wx_promp',
                // component: () => import(/* webpackChunkName: "about" */ '../views/ceshi.vue')
                component: () => import(/* webpackChunkName: "about" */ '../views/account/wx_promp.vue')

            },
            {
                path: '/ceshi',
                name: 'ceshi',
                component: () => import(/* webpackChunkName: "about" */ '../views/ceshi.vue')
            },

        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/account/login.vue')
    },
    {
        path: '/term',
        name: 'term',
        component: () => import(/* webpackChunkName: "about" */ '../views/account/term.vue')
    },
    {
        path: '/test',
        name: 'test',
        component: () => import(/* webpackChunkName: "about" */ '../views/education/test.vue')
    },
    {
        path: '/testView',
        name: 'testView',
        component: () => import(/* webpackChunkName: "about" */ '../views/education/testView.vue')
    }
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


export default router
