<template>
    <div class="chat_body"
         v-if="!isMobile">
        <!--    <div class="chat_body"-->
        <!--         v-if="!isMobile">-->
        <uploadMsg v-if="isshow"></uploadMsg>
        <div class="chat_content">
            <Navs class="chat_left"></Navs>
            <router-view class="chat_midle"></router-view>
            <Ravs class="chat_right" v-show="showRav"></Ravs>
        </div>
    </div>
    <div class="chat_body" v-else>
        <uploadMsg v-if="isshow"></uploadMsg>
        <div class="left_mobile" :class="{ 'lslide-in':  showLeftMobile,'lslide-out': !showLeftMobile}">
            <Navs v-show="showLeftMobile"></Navs>
            <div @click="click_closeBtn('left')"></div>
        </div>

        <div class="right_mobile" :class="{ 'rslide-in':  showRightMobile,'rslide-out': !showRightMobile}">
            <div @click="click_closeBtn('right')" v-show="showRav"></div>
            <Ravs v-show="showRightMobile"></Ravs>
        </div>
        <div class="middle" :style="{backgroundColor: showLeftMobile || showRightMobile ? 'rgba(0, 0, 0, 0.5)' : '' ,
        filter: showLeftMobile || showRightMobile ? 'brightness(0.5)' : ''}">
            <div class="Mobiletitle">
                <button class="left-button" @click="click_leftBtn()">
                    <i class="fas fa-bars"></i>
                </button>
                <div class="title">
                    {{ title }}
                </div>
                <button class="right-button">
                    <i class="fas fa-user-tag" @click="click_rightBtn()" v-show="showRav"></i>
                </button>
            </div>
            <router-view class="chat_midle"></router-view>
        </div>

    </div>
</template>

<script setup>
import Navs from './Navs.vue'
import Ravs from './Ravs.vue'
import {ref, watch} from "vue";
import axios from "axios";
import config from "@/api/api";

</script>

<script>
import axios from "axios";
import config from "@/api/api";
import uploadMsg from "../Modal-box/uploadMsg";
import {watch} from "vue";

"设置登陆和注册页面不使用导航栏"
export default {
    data() {
        return {
            user_id: '',
            isshow: false,
            showRav: true,
            isMobile: false,
            showLeftMobile: false,
            showRightMobile: false,
            title: ''
        };
    },
    mounted() {
        // 初始化
        this.get_UserData();
        // 在组件挂载后，检测屏幕宽度，根据不同的宽度设置isMobile的值
        this.checkScreenWidth();
        //设置
        window.addEventListener('resize', this.checkScreenWidth);
    },
    created() {
        if (localStorage.getItem('token')) {
            watch(() => this.$store.state.titles[localStorage.getItem('token')], () => {
                this.showRav = this.$store.state.base_msgs[localStorage.getItem('token')].chat_id >= 10000;
                this.title = this.$store.state.base_msgs[localStorage.getItem('token')].title;
                this.click_closeBtn('all')
            });
            watch(() => this.$store.state.robot_ids[localStorage.getItem('token')], () => {
                this.showRightMobile = false
            });
        }
    },
    beforeDestroy() {
        // 在组件销毁前移除resize事件监听器
        window.removeEventListener('resize', this.checkScreenWidth);
    },
    methods: {
        //判断登录情况
        get_UserData() {
            const data = {
                token: localStorage.getItem('token')
            };
            //保存用户信息
            return axios.post(config.API_BASE_URL + 'front/check_usermsg', data)
                .then(response => {
                    // 请求成功的处理逻辑
                    if (response.data.state === 0) {

                        this.user_id = localStorage.getItem('token')
                        this.$store.state.users[this.user_id] = response.data.userinfo
                        this.$store.state.userFinance[this.user_id] = response.data.UserInfo_finance
                        this.init_mongoDB(response.data.userinfo.id)
                    } else if (response.data.state === 1) {
                        this.$router.push('/login');
                    } else if (response.data.state === 2) {
                        this.isshow = true
                    } else if (response.data.state === 3) {
                        localStorage.removeItem('token')
                        this.$router.push('/login');
                    }
                })
                .catch(error => {
                    // 请求失败的处理逻辑
                    console.error(error);
                    alert(error);
                });
            //保存聊天信息

        },
        //判断是客户端还是移动端
        checkScreenWidth() {
            // 根据屏幕宽度设置isMobile的值
            this.isMobile = window.innerWidth <= 768; // 设置一个屏幕宽度阈值，小于等于768px视为手机设备

        },
        //初始化用户个人的mongoDB
        init_mongoDB(user_id) {
            return axios.post(config.API_GPT_URL + 'get_userMsg', {'type': 'all', 'user_id': user_id})
                .then(response => {
                    // 请求成功的处理逻辑

                    this.$store.state.base_msgs[this.user_id] = response.data.chat_msg//获取基本数据
                    this.$store.state.robot_ids[this.user_id] = response.data.robot_id//获取聊天机器人
                    this.$store.state.titles[this.user_id] = response.data.chat_msg.page//获取标题栏
                    this.changeBack(response.data.chat_msg.styleBack)//设置板块样式
                    this.title = response.data.chat_msg.title//获取标题
                    this.$router.push({name: response.data.chat_msg.url})//跳转页面


                })
                .catch(error => {
                    // 请求失败的处理逻辑
                    console.error(error);
                    alert(error);
                })
                .finally(() => {
                });
        },
        //设置样式
        changeBack(selectedOption) {
            const body = document.querySelector('body');
            if (selectedOption === 'lightColor') {
                //设置背景
                body.classList.remove('deep-background', 'glass-background');//设置背景颜色
                body.classList.add('light-background');
                //设置颜色
                //字体
                document.documentElement.style.setProperty('--blue-color', '#0031d2');//改变蓝色字体
                document.documentElement.style.setProperty('--white-color', '#000000');//改变白色字体
                document.documentElement.style.setProperty('--gray-color', '#363636');//改变灰色字体
                document.documentElement.style.setProperty('--mmcSearch-color', '#0031d2');//改变mmc搜索字体

                //边线
                //下边框
                document.documentElement.style.setProperty('--bottom-titleBorder', '1px solid rgba(214, 214, 214)');//改变标题栏下边框
                document.documentElement.style.setProperty('--bottom-border', '1px solid rgba(214, 214, 214)');//改变下边框
                document.documentElement.style.setProperty('--blue-border', '2px solid rgba(59,58,190)');//改变蓝色边框

                //阴影
                document.documentElement.style.setProperty('--bottom-shadow', 'none');//设置下方阴影
                //背景
                document.documentElement.style.setProperty('--block-background', '#fffffe');//总背景色
                document.documentElement.style.setProperty('--block-color', '#e9e9e9');//改变方块背景
                document.documentElement.style.setProperty('--middleBlock-color', '#ffffff');//改变方块背景
                document.documentElement.style.setProperty('--chatLeft-color', '#e9e9e9');//改变聊天输出方块背景
                document.documentElement.style.setProperty('--chatRight-color', '#3B3ABE');//改变聊天输入方块背景
                // document.documentElement.style.setProperty('--white-color', '#000000');//改变白色字体


            } else if (selectedOption === 'deepColor') {
                //设置背景
                body.classList.remove('light-background', 'glass-background');
                body.classList.add('deep-background');
                //设置颜色
                //字体
                document.documentElement.style.setProperty('--blue-color', '#a0b6ff');//改变蓝色字体
                document.documentElement.style.setProperty('--white-color', '#ffffff');//改为白色字体
                document.documentElement.style.setProperty('--gray-color', '#C1C1C1');//改变灰色字体
                document.documentElement.style.setProperty('--mmcSearch-color', '#D6DDEE');//改变mmc搜索字体
                //边线
                //下边框
                document.documentElement.style.setProperty('--bottom-titleBorder', 'none');//改变标题栏下边框
                document.documentElement.style.setProperty('--bottom-border', '1px solid rgba(255, 255, 255, .3)');//改变正常
                document.documentElement.style.setProperty('--blue-border', '2px solid white');//改变蓝色边框

                //阴影
                document.documentElement.style.setProperty('--bottom-shadow', '0 0 30px 10px rgba(0, 0, 0, 0.3)');//设置下方阴影

                //背景
                document.documentElement.style.setProperty('--block-background', '#333');//总背景色
                document.documentElement.style.setProperty('--block-color', '#3b3b3b');//改变方块背景
                document.documentElement.style.setProperty('--middleBlock-color', 'transparent');//改变方块背景
                document.documentElement.style.setProperty('--chatLeft-color', 'transparent');//改变聊天输出方块背景
                document.documentElement.style.setProperty('--chatRight-color', '#5D5CDE');//改变聊天输入方块背景

            }
        },


        //------------------手机端----------------
        // 左侧展示框
        click_leftBtn() {
            this.showLeftMobile = true;

        },
        //右侧展示框
        click_rightBtn() {
            this.showRightMobile = true;
        },
        //滑动框取消显示
        click_closeBtn(type) {
            if (type === 'left') {
                this.showLeftMobile = false;
            } else if (type === 'right') {
                this.showRightMobile = false;
            } else {
                this.showLeftMobile = false;
                this.showRightMobile = false;
            }

        }
    }
};
</script>

<style scoped>
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css';
</style>
<style scoped lang="less">
/*-----------------全模式-----------------------------*/
.chat_body {
  height: 100vh;
  margin: 0 auto;
  overflow-y: hidden; /* 隐藏垂直滚动条 */
  overflow-x: hidden; /* 隐藏垂直滚动条 */
  background-size: 100%;
  width: 100%;
  position: relative;

}


.middle {
  //backdrop-filter: blur(3px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;

}

.left_mobile {
  //margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  display: grid;
  transform: translateX(0%);
  transition: transform 0.3s ease-out; /* 添加过渡效果 */
}

.right_mobile {
  //margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  display: grid;
  transform: translateX(0%);
  transition: transform 0.3s ease-out; /* 添加过渡效果 */
}

.lslide-in {
  transform: translateX(0%);
}

.lslide-out {
  transform: translateX(-100%);
}

.rslide-in {
  transform: translateX(0%);
}

.rslide-out {
  transform: translateX(100%);
}

.chat_content {
  display: grid;
  margin: 0 auto;

}

.chat_midle {
  width: auto;
}

/*-----------------手机模式-----------------------------*/
@media screen and (max-width: 768px) {
  .Mobiletitle {
    //长宽
    width: 100%;
    height: 8vh;
    //玻璃化
    backdrop-filter: blur(20px);
    box-shadow: var(--bottom-border);
    border-bottom: var(--bottom-titleBorder);
    //color: white;
    color: var(--white-color); /* 使用CSS变量作为颜色值 */
    font-weight: bold;
    //文字居中
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Mobiletitle .title {
    text-align: center;
    width: 70%;
    font-size: 120%;
    //margin-left: 25%;

  }

  .Mobiletitle .left-button,
  .Mobiletitle .right-button {
    width: 7%;
    height: 40px;
    background-color: transparent;
    border: none;
  }

  .Mobiletitle .left-button i,
  .Mobiletitle .right-button i {
    color: var(--gray-color);
    font-size: 140%;
  }

  //左右滑出栏
  .left_mobile {
    grid-template-columns: 45% 55%;
  }

  .right_mobile {
    grid-template-columns: 40% 60%;
  }

}

/*-----------------平板模式-------------------------------*/
@media screen and (min-width: 768px) and (max-width: 899px) {
  .Mobiletitle {
    //长宽
    width: 100%;
    height: 8vh;
    //玻璃化
    backdrop-filter: blur(20px);
    box-shadow: var(--bottom-border);
    border-bottom: var(--bottom-titleBorder);
    //color: white;
    color: var(--white-color); /* 使用CSS变量作为颜色值 */
    font-weight: bold;
    //文字居中
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Mobiletitle .title {
    text-align: center;
    width: 70%;
    font-size: 150%;

  }

  .Mobiletitle .left-button,
  .Mobiletitle .right-button {
    width: 7%;
    height: 40px;
    background-color: transparent;
    border: none;
  }

  .Mobiletitle .left-button i,
  .Mobiletitle .right-button i {
    color: var(--gray-color);
    font-size: 140%;
  }
  //左右滑出栏
  .left_mobile {
    grid-template-columns: 30% 60%;
  }

  .right_mobile {
    grid-template-columns: 65% 35%;
  }
}

/*-----------------小电脑模式-----------------------------*/
@media screen and (min-width: 900px) and (max-width: 1119px) {
  .chat_body {
    height: 100vh;
    margin: 0 auto;
    overflow-y: hidden; /* 隐藏垂直滚动条 */
    background-size: 100%;
    width: 100%;
  }

  .chat_content {
    grid-template-columns: 18% auto 18%;
  }
}

/*-----------------中电脑模式-----------------------------*/
@media screen and (min-width: 1200px) and (max-width: 2199px) {
  .chat_body {
    height: 100vh;
    margin: 0 auto;
    overflow-y: hidden; /* 隐藏垂直滚动条 */
    background-size: 100%;
    width: 100%;
  }

  .chat_content {
    grid-template-columns: 15% auto 15%;
  }
}

/*-----------------大电脑模式-----------------------------*/
@media screen and (min-width: 2200px) {
  .chat_body {
    height: 100vh;
    margin: 0 auto;
    overflow-y: hidden; /* 隐藏垂直滚动条 */
    background-size: 100%;
    width: 100%;
  }

  .chat_content {
    grid-template-columns: 10% auto 10%;
  }
}


</style>